import { notification } from 'antd';
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  onSnapshot,
  Timestamp,
} from 'firebase/firestore';
import _ from 'lodash';
import { db } from '../firebase/Fb';
import { PayPeriodType } from '../models/payroll';
import { ITDate } from './Employee';

export interface Company {
  reference: DocumentReference;
  key: string; // Uuid
  address: PhysicalAddress;
  name: string;
  operatingName: string;
  phone: string;
  stores: Store[];
  pStores: Promise<Store[]>;
  permission: string | undefined;
  configurations: Map<string, string>;
  payPeriodType: PayPeriodType;
  payPeriodStartDate: ITDate;
  payPeriodLocked: boolean;
  payrollnumber: string;
  firstname: string;
  lastname: string;
  createdTime?: Timestamp;
  trial?: boolean;
}

export class CompanyConfiguration {
  flags: Map<string, string> | undefined;

  constructor(data: Map<string, string>) {
    this.flags = data;
  }

  isActive(name: string) {
    return this.flags?.has(name) ?? false;
  }

  getValue(name: string) {
    return this.flags?.get(name) ?? '';
  }
}

export interface UserInfo {
  firstname: string;
  lastname: string;
  admin: boolean;
  companies: { [key: string]: string };
  stores: { [key: string]: string };
  demoUser: boolean;
}

export interface Store {
  reference: DocumentReference;
  key: string; // Uuid
  address: PhysicalAddress;
  name: string;
  phone: string;
  businessnumber: string;
  permission?: string;
  feesInclusive: boolean;
  maxDiscount: number;
  invoiceType: string;
}

export interface PhysicalAddress {
  address: string;
  city: string;
  country: string;
  postalcode: string;
  province: string;
}

const getStores = async (
  companyId: string,
  permission: { [key: string]: string }
) => {
  try {
    if (!companyId) {
      throw Error('Missing companyId!');
    }
    console.log('Requesting for Stores.');
    const collectionReference = await getDocs(
      collection(db, `companies/${companyId}/stores`)
    );
    return collectionReference.docs.map((documentReference) => {
      const store = documentReference.data() as Store;
      store.reference = documentReference.ref;
      store.key = documentReference.id;
      store.permission = permission[documentReference.id];
      store.feesInclusive = store.feesInclusive ?? false;
      store.maxDiscount = store.maxDiscount ?? 100;
      store.invoiceType = store.invoiceType ?? '';
      return store;
    });
  } catch (error: any) {
    notification['error']({
      message: 'Database Error',
      description: error.toString(),
    });
    return [];
  }
};

const getCompany = async (companyId: string) => {
  try {
    if (!companyId) {
      throw Error('Missing companyId!');
    }
    console.log('Requesting for Company.');
    const documentReference = await getDoc(doc(db, `companies/${companyId}`));
    const company = documentReference.data() as Company;
    if (company) {
      company.reference = documentReference.ref;
      company.key = documentReference.id;
    }
    return company;
  } catch (error: any) {
    notification['error']({
      message: 'Database Error',
      description: error.toString(),
    });
    return undefined;
  }
};

const getCompanies = async (userInfo: UserInfo) => {
  try {
    if (userInfo?.companies) {
      const companies = await Promise.all(
        Object.keys(userInfo.companies).map((companyId: string) => {
          return getCompany(companyId);
        })
      );
      for (const company of companies) {
        if (company) {
          company.permission = userInfo.companies[company.key];
          company.pStores = getStores(company.key, userInfo.stores)
            .then((value) => {
              company.stores = value;
              return value;
            })
            .catch((reason) => {
              return [];
            });
        }
      }
      await Promise.all(companies.map((company) => company?.pStores));
      return _.compact(companies);
    }
    return [];
  } catch (error: any) {
    notification['error']({
      message: 'Database Error',
      description: error.toString(),
    });
    return [];
  }
};

const StreamCompany = (
  companyId: string,
  observer: {
    next?: ((snapshot: DocumentSnapshot<DocumentData>) => void) | undefined;
    error?: ((error: Error) => void) | undefined;
    complete?: (() => void) | undefined;
  }
) => {
  if (companyId) {
    console.log('Requesting for Company.');
    return onSnapshot(doc(db, `companies/${companyId}`), observer);
  }
};

const isCompanyTrialExpired = (company: Company) => {
  if (company.trial) {
    return false;
  }
  if (Object.keys(company?.configurations ?? {}).length === 0) {
    return true;
  }
  return false;
};

export { getCompanies, StreamCompany, isCompanyTrialExpired };
